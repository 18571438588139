export const ctaLogsInitialState = {
  CTALogsFormData: {
    formEnquiries: [],
    formEnqCount: 0,
  },
  CTALogsChatData: {
    chats: [],
    chatCount: 0,
  },
  CTALogsPhoneData: {
    phoneEnquiries: [],
    phoneEnqCount: 0,
  },
  timeFrame: 'past_30',
  reportsData: {
    files: [],
    reportsCount: 0,
  },
  pdfDownloadUrl: '',

};

export const ctaLogsReducer = (state = ctaLogsInitialState, { type, payload } = {}) => {
  switch (type) {
  case 'SET_CTA_LOGS_FORM_DATA':
    return {
      ...state,
      CTALogsFormData: payload,
    };
  case 'SET_CTA_LOGS_CHAT_DATA':
    return {
      ...state,
      CTALogsChatData: payload,
    };
  case 'SET_CTA_LOGS_PHONE_DATA':
    return {
      ...state,
      CTALogsPhoneData: payload,
    };
  case 'SET_TIME_FRAME':
    return {
      ...state,
      timeFrame: payload,
    };
  case 'SET_REPORTS':
    return {
      ...state,
      reportsData: payload,
    };
  case 'SET_PDF_DOWNLOAD_URL':
    return {
      ...state,
      pdfDownloadUrl: payload,
    };
  default:
    throw new Error(`Unhandled action type: ${type}`);
  }
};
