import { getCookie } from '../components/Helper';

export const authInitialState = {
  user: null,
  token: getCookie('USER_TOKEN') || '',
  loading: false,
  instance: getCookie('INSTANCE_ID') || '',
  deviceToken: null,
};

export const AuthReducer = (state = authInitialState, { type, payload } = {}) => {
  switch (type) {
  case 'LOGIN_SUCCESS':
    return {
      ...state,
      user: payload,
      token: payload.token,
    };

  case 'GET_USER':
    return {
      ...state,
      user: payload,
    };
  case 'LOGOUT':
    return {
      ...state,
      user: '',
      token: '',
    };
  case 'SHOW_LOADER':
    return {
      ...state,
      loading: true,
    };
  case 'HIDE_LOADER':
    return {
      ...state,
      loading: false,
    };
  case 'SET_DEVICE_TOKEN':
    return {
      ...state,
      deviceToken: payload,
    };

  default:
    throw new Error(`Unhandled action type: ${type}`);
  }
};
