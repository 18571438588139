import { useState, useEffect } from 'react';
import { displayError } from '../Helper';

function NoInternetConnection(props) {
  const [isOnline, setOnline] = useState(true);

  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  window.addEventListener('online', () => {
    setOnline(true);
  });

  window.addEventListener('offline', () => {
    setOnline(false);
  });

  if (isOnline) {
    return (
      props.children
    );
  }
  return (displayError('No Internet Connection'));
}

export default NoInternetConnection;
