/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { ToastContainer } from 'react-toastify';
import ErrorBoundary from './components/ErrorBoundary';
import Loader from './components/Loader';
import NoInternetConnection from './components/NoInternetConnection';
import { AppProvider } from './context';
import AppRoutes from './AppRoutes';
import firebaseConfig from './firebaseConfig';
import 'react-toastify/dist/ReactToastify.css';
import Notifications from './notification';

window.addEventListener('load', () => {
  navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/' });
});

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics
getAnalytics(app);

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <AppRoutes />
    </Suspense>
  );
}

function AppWithProvider() {
  return (
    <NoInternetConnection>
      <ErrorBoundary>
        <AppProvider>
          <Notifications />
          <App />
          <Loader />
          <ToastContainer />
        </AppProvider>
      </ErrorBoundary>
    </NoInternetConnection>
  );
}
export default AppWithProvider;
