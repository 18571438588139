/* eslint-disable eqeqeq */
import { toast } from 'react-toastify';

export const getSelectedOption = (options, selectedOption) => {
  const data = options.filter((item) => (item.value == selectedOption))[0];
  return data || null;
};

export const displayError = (message) => toast.error(message, {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

export const displaySuccess = (message) => toast(message, {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

export const setCookie = (cName, cValue, expDays, path) => {
  const date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${cName}=${cValue}; expires=${expires}; path=${path};`;
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const getSentenceFromCamelCase = (message) => {
  const pattern = /[A-Za-z]/g;
  const messages = message.match(pattern);
  let errorMessage = '';
  for (let i = 0; i < messages.length; i += 1) {
    errorMessage
      += messages[i] === messages[i].toUpperCase()
        ? ` ${messages[i].toLowerCase()}`
        : messages[i];
  }
  return (errorMessage[0].toUpperCase() + errorMessage.slice(1)).trim();
};

export const selectColorStyles = {
  control: (styles, state) => ({
    ...styles,
    boxShadow: 'none',
    border: 'none',
    borderBottom: state.isFocused ? '#50b0cf' : '#000000',
    width: '100%',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? '#FFFFFF' : '#000000 !important',
    backgroundColor: state.isSelected ? '#50b0cf' : '#FFFFFF',
    borderBottom: '1px solid #cccccc',
    fontSize: '16px',
    width: '100%',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
  }),
  singleValue: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? '#666766' : '#00000',
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#666766',
    display: state.isDisabled ? 'none' : 'block',
    svg: {
      height: 35,
      width: 35,
    },
  }),
};

export const secondsToHms = (e) => {
  const d = Number(e);
  // const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  // const hDisplay = h > 0 ? `${h}h` : "";
  const mDisplay = m > 0 ? `${m}m` : '';
  const sDisplay = s > 0 ? `${s}s` : '';
  return `${mDisplay} ${sDisplay}`;
};

export const getFlagEmoji = (countryCode) => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};
