import React, { useEffect } from 'react';
import {
  Row, Col, Button,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import { useAuthState, useAuthDispatch } from '../../context';
import { getCookie, displayError, setCookie } from '../../components/Helper';
import Loader from '../../components/Loader';
import GoogleIcon from '../../assets/images/Google.svg';
import QRScanIcon from '../../assets/images/scan.svg';
import getDeviceToken from '../../getDeviceToken';
import LoginAPI from '../../services/user';

function LoginOptions() {
  const navigate = useNavigate();
  const dispatch = useAuthDispatch();
  const { loading } = useAuthState();
  const instanceId = getCookie('INSTANCE_ID');

  useEffect(() => {
    if (instanceId) {
      navigate('/dashboard');
    }
  }, [instanceId]);

  const setUserLoginData = async (response) => {
    try {
      const { data } = response.data;
      setCookie('USER_TOKEN', data.token, 9999, '/');
      await dispatch({ type: 'LOGIN_SUCCESS', payload: data });
      navigate('/instances');
    } catch (err) {
      displayError(err);
    }
  };

  const onHandleSuccessResponse = async (e) => {
    const { tokenId } = e;
    const sendParams = { token_id: tokenId };
    getDeviceToken().then(async (deviceToken) => {
      if (deviceToken) {
        sendParams.user_device = { platform: 'android', device_token: deviceToken };
      }
      const response = await LoginAPI.googleLogin(sendParams);
      setUserLoginData(response);
    });
  };

  const onHandleFailure = (e) => {
    displayError({ message: e.details });
  };

  return (
    <>
      {loading && <Loader />}
      <>
        <Row className="my-2">
          <Col sm={{ size: 12 }}>
            <GoogleLogin
              clientId="190603057562-3qhh4hvqvhtdoa0ef0tj5ho9rvspcbjk.apps.googleusercontent.com"
              onSuccess={onHandleSuccessResponse}
              onFailure={onHandleFailure}
              cookiePolicy="single_host_origin"
              render={(renderProps) => (
                <Button
                  className="btn-white shadow"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  <div className="gap-3 d-flex align-items-center px-3 fw-500">
                    <img src={GoogleIcon} alt="google-icon" />
                    <div>
                      Sign in with Google
                    </div>
                  </div>
                </Button>
              )}
            />
          </Col>
        </Row>

        <Row className="my-2">
          <Col sm={{ size: 12 }}>
            <Button
              className="btn-green shadow"
              onClick={() => navigate('/sign-in')}
              style={{ whiteSpace: 'nowrap' }}
            >
              <div className="gap-3 d-flex align-items-center px-3 fw-500">
                <i className="fa-regular fa-envelope" />
                <div>
                  Email address &amp; password
                </div>
              </div>

            </Button>
          </Col>
        </Row>

        <Row className="my-2">
          <Col sm={{ size: 12 }}>
            <Button
              className="btn-blue shadow"
              onClick={() => navigate('/approval')}
            >
              <div className="gap-3 d-flex align-items-center px-3 fw-500">
                <img src={QRScanIcon} alt="QRScanIcon" />
                <div>
                  Scan QR Code
                </div>
              </div>

            </Button>
          </Col>
        </Row>
      </>
    </>
  );
}

export default LoginOptions;
