/* eslint-disable no-case-declarations */
import React, { useReducer } from 'react';
import { authInitialState, AuthReducer } from './auth';
import { ctaLogsInitialState, ctaLogsReducer } from './ctaLogs';

const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();
const CTALogsStateContext = React.createContext();
const CTAlogsDispatchContext = React.createContext();

export function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AppProvider');
  }

  return context;
}

export function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AppProvider');
  }

  return context;
}
export function useCTAlogsState() {
  const context = React.useContext(CTALogsStateContext);
  if (context === undefined) {
    throw new Error('useCTAlogsState must be used within a AppProvider');
  }

  return context;
}

export function useCTAlogsDispatch() {
  const context = React.useContext(CTAlogsDispatchContext);
  if (context === undefined) {
    throw new Error('useCTAlogsDispatch must be used within a AppProvider');
  }

  return context;
}

export function AppProvider({ children }) {
  const [user, authDispatch] = useReducer(AuthReducer, authInitialState);
  const [ctalogs, ctalogsDispatch] = useReducer(ctaLogsReducer, ctaLogsInitialState);

  return (
    <AuthStateContext.Provider value={user}>
      <AuthDispatchContext.Provider value={authDispatch}>
        <CTALogsStateContext.Provider value={ctalogs}>
          <CTAlogsDispatchContext.Provider value={ctalogsDispatch}>
            {children}
          </CTAlogsDispatchContext.Provider>
        </CTALogsStateContext.Provider>
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}
