import React from 'react';
import spinner from '../../assets/images/spinner.webp';
import './index.scss';
import { useAuthState } from '../../context';

function Loader() {
  const { loading } = useAuthState();
  if (!loading) return null;

  return (
    <div className="loader">
      <img src={spinner} alt="spinner" className="loader-image" />
    </div>
  );
}
export default Loader;
