import React, { useEffect, lazy } from 'react';
import {
  BrowserRouter as Router, Route, Routes, Navigate, useLocation, Outlet,
} from 'react-router-dom';
import { useAuthState, useAuthDispatch } from './context';
import User from './services/user';
import LoginOptions from './views/users/loginOptions';
import registerForegroundNotifications from './registerForegroundNotifications';

const UserLayout = lazy(() => import('./userLayout'));
const AppLayout = lazy(() => import('./appLayout'));
const SignIn = lazy(() => import('./views/users/signIn'));
const DashBoard = lazy(() => import('./views/dashboard'));
const FormEnquiriesListing = lazy(() => import('./views/formEnquiriesListing'));
const ChatListing = lazy(() => import('./views/chatListing'));
const FormEnquiryDetails = lazy(() => import('./views/formEnquiryDetails'));
const ChatDetails = lazy(() => import('./views/chatDetails'));
const PhoneEnquiriesLisiting = lazy(() => import('./views/phoneEnquiriesListing'));

const InstancesAndSites = lazy(() => import('./views/instancesAndSite'));
const Support = lazy(() => import('./views/Support'));
const ChangeInstance = lazy(() => import('./views/changeInstance'));
const Approval = lazy(() => import('./views/approval'));
const Reports = lazy(() => import('./views/reports'));
const PdfView = lazy(() => import('./views/pdfView'));
const Users = lazy(() => import('./views/users/userList'));

function RequireAuth({ authUser }) {
  const location = useLocation();
  if (!authUser) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return (
    <Outlet />
  );
}

function AuthRoutes() {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const { token: userToken, user } = userDetails;
  useEffect(() => {
    async function fetchData() {
      if (userToken && !user) {
        dispatch({ type: 'SHOW_LOADER' });
        try {
          const response = await User.profile();
          const { data } = response.data;
          dispatch({ type: 'GET_USER', payload: data });
          dispatch({ type: 'HIDE_LOADER' });
        } catch (err) {
          dispatch({ type: 'HIDE_LOADER' });
        }
      }
    }
    fetchData();
  }, [dispatch]);

  useEffect(() => registerForegroundNotifications(!!userToken), []);

  return (
    <Router>
      <Routes>
        <Route element={<UserLayout />}>
          <Route exact path="/sign-in" element={<SignIn />} />
          <Route path="/approval" element={<Approval />} />
        </Route>
        <Route element={<RequireAuth authUser={userToken} />}>
          <Route element={<UserLayout />}>
            <Route path="/instances" element={<InstancesAndSites />} />
          </Route>
          <Route element={<AppLayout />}>
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/form-enquiries" exact element={<FormEnquiriesListing />} />
            <Route path="/chats" exact element={<ChatListing />} />
            <Route path="/form-enquiries/instance/:instanceId/ctaLog/:ctaLogId" element={<FormEnquiryDetails />} />
            <Route path="/chats/instance/:instanceId/ctaLog/:ctaLogId" element={<ChatDetails />} />
            <Route path="/phone-enquiries" exact element={<PhoneEnquiriesLisiting />} />
            <Route path="/instance/:instanceId/support" element={<Support />} />
            <Route path="/change-instance/:instanceId" element={<ChangeInstance />} />
            <Route path="/change-instance/" element={<ChangeInstance />} />
            <Route path="/users" element={<Users />} />
            <Route path="/instance/:instanceId/reports" element={<Reports />} />
            <Route path="/instance/:instanceId/report" element={<PdfView />} />
          </Route>
        </Route>
        <Route element={<UserLayout />}>
          <Route path="/" element={<LoginOptions />} />
        </Route>
      </Routes>
    </Router>
  );
}
export default AuthRoutes;
