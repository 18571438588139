import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import firebaseConfig from './firebaseConfig';

const getDeviceToken = () => {
  const { REACT_APP_V_API_KEY } = process.env;

  return new Promise((resolve, reject) => {
    isSupported().then(async (supported) => {
      if (!supported) {
        resolve(null);
      } else {
        try {
          navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/' }).then((registration) => {
            const firebaseApp = initializeApp(firebaseConfig, 'wg');
            const messaging = getMessaging(firebaseApp);

            getToken(messaging, { vapidKey: REACT_APP_V_API_KEY, serviceWorkerRegistration: registration }).then((token) => {
              if (token) {
                console.log('Device token:', token);
                resolve(token);
                return;
              }

              console.log('No device token available.');
              reject(new Error('No device token available.'));
            }).catch((err) => {
              // This probably happens because the user disallowed notifications, so we just pass null along.
              console.log('An error occurred while retrieving token:', err);
              resolve(null);
            });
          });
        } catch (error) {
          // Firebase not supported
          console.log('Firebase not supported:', error);
          reject(error);
        }
      }
    });
  });
};

export default getDeviceToken;
