const firebaseConfig = {
  apiKey: 'AIzaSyCjUnpPY8V0U2GkYVUUiNyVIbYyLWLX8Jk',
  authDomain: 'web-genius-ios-app.firebaseapp.com',
  projectId: 'web-genius-ios-app',
  storageBucket: 'web-genius-ios-app.appspot.com',
  messagingSenderId: '579754793749',
  appId: '1:579754793749:web:1bb693add6cd360bf8e71c',
  measurementId: 'G-8GG61LRDXN',
};

export default firebaseConfig;
