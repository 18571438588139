/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import { displayError, getCookie } from '../components/Helper';

// export const baseDomain = 'https://dev-admin.webgenius.co.nz';
export const baseDomain = 'https://admin.webgenius.co.nz';
export const baseURL = `${baseDomain}/api/v1`;

const api = axios.create({
  baseURL,
});

const errorHandler = (error) => {
  if (error) {
    const {
      response: {
        data: { messages },
      },
    } = error;
    displayError(messages);
  }
};

const successHandler = (response) => response;

api.interceptors.request.use((config) => {
  const userToken = getCookie('USER_TOKEN');
  if (userToken) {
    config.headers['x-user-token'] = userToken;
  }
  return config;
}, (error) => errorHandler(error));

api.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

export default api;
