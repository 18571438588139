import { useEffect } from 'react';
import { isSupported } from 'firebase/messaging';
import { useAuthDispatch } from './context';
import getDeviceToken from './getDeviceToken';

function Notifications() {
  const dispatch = useAuthDispatch();

  useEffect(async () => {
    isSupported().then(async (supported) => {
      if (!supported) {
        return;
      }

      getDeviceToken().then((token) => dispatch({ type: 'SET_DEVICE_TOKEN', payload: token }));
    });
  }, []);

  return '';
}

export default Notifications;
