import { initializeApp } from 'firebase/app';
import { isSupported, getMessaging, onMessage } from 'firebase/messaging';
import firebaseConfig from './firebaseConfig';

const registerForegroundNotifications = (isAuthenticated) => {
  isSupported().then((supported) => {
    if (!supported) {
      return;
    }

    const firebaseApp = initializeApp(firebaseConfig, 'wg');
    const messaging = getMessaging(firebaseApp);

    onMessage(messaging, (payload) => {
      console.log('Received foreground message:', payload);

      if (!isAuthenticated) {
        console.log('Not authenticated, ignoring.');
        return;
      }

      const { data: { cta_log_id, instance_id, type } } = payload;

      if (type === 'form_submission') {
        window.location = `/form-enquiries/instance/${instance_id}/ctaLog/${cta_log_id}`;
      } else if (type === 'reports') {
        window.location = `/instance/${instance_id}/reports`;
      } else if (type === 'chat') {
        window.location = `/chats/instance/${instance_id}/ctaLog/${cta_log_id}`;
      }
    });
  });
};

export default registerForegroundNotifications;
