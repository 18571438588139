import client from './index';
import { getCookie } from '../components/Helper';

export default {
  profile: () => client.get('profile'),
  createUser: (params) => client.post('login', params),
  getUsers: (params) => client.get('/users', { params }),
  qrCodeImage: (params) => client.get('/qrcode/image', { ...params, responseType: 'arraybuffer' }),
  googleLogin: (params) => client.post('/google_login', params),
  logoutUser: (params) => client.delete('logout', {
    params,
    headers: { 'x-user-token': getCookie('USER_TOKEN') },
  }),
};
